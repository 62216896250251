import React from "react";
import "../App.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import TextField from "@mui/material/TextField";
import FooterRow from "./FooterRow";
// receive response
export default function UserSignup() {
  let navigate = useNavigate();
  const businessTypes = [
    { value: "goods", label: "I sell goods(things)" },
    { value: "services", label: "I offer services" },
    { value: "restaurantCafe", label: "I have a Restaurant/cafe" },
    { value: "platform", label: "I have a platform" },
  ];
  // initialise state variables
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [email, setEmail] = useState("");
  const [isTermsChecked, setIsTermsChecked] = useState(false);

  const [validationErrors, setError] = useState({
    nameError: "",
    phoneError: "",
  });

  // get form values
  const getFormValues = (e) => {
    e.preventDefault();
    //clear errors if any
    setError({
      nameError: "",
      phoneError: "",
    });
    let form = document.forms.preRegForm;
    let input = {
      name: form.name.value,
      phone: form.phone.value,
      //businessType: form.businessType.value,
      email: form.email.value,
    };
    validateInput(input);
  };

  // validate form values
  const validateInput = (input) => {
    let error = false;
    if (input.name.length < 3) {
      error = true;
      setError((previousState) => {
        return { ...previousState, nameError: "Name is too short" };
      });
    }
    if (input.phone.length < 10) {
      error = true;
      setError((previousState) => {
        return { ...previousState, phoneError: "Phone Number is Invalid" };
      });
    }

    if (!error) {
      createUser();
    }
  };

  // send to backend
  const createUser = async () => {
    // send to backend
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        phone: phone,
        businessType: "APP/API",
        email: email,
      }),
    };
    console.log(requestOptions.body);
    const res = await fetch(
      "https://developer.phindor.com/phindor-api/public/api/wishlist/onboard",
      requestOptions
    );
    var data = await res.json();
    console.log(data);

    data.success
      ? navigate("/welcome/" + name)
      : alert("Sorry there was an issue with the server. Please try again.");
  };

  return (
    <>
      <span className="page-heading p-1 mx-3">lisa</span>
      <form
        className="register-card mx-5"
        name="preRegForm"
        onSubmit={getFormValues}
      >
        <div className="p-1">
          <div className="m-5">
            <p className="form-heading">PRE-REGISTER FOR LISA APP</p>
            <p className="form-subheading">
              Pre-register your business to get
              <span className="bold"> KES3000 </span>worth of credit to learn
              and grow your business
            </p>
            <div className="my-3">
              <label className="form-label">Your Name</label>
              <TextField
                id="outlined-name"
                label="Full Name"
                required
                variant="outlined"
                className="text-filled"
                name="name"
                type="text"
                size="small"
                fullWidth
                inputProps={{ style: { fontSize: 10 } }}
                InputLabelProps={{ style: { fontSize: 10 } }}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <span className="d-block text-danger">
                <small>{validationErrors.nameError}</small>
              </span>
            </div>
            <div className="my-3">
              <label className="form-label">Phone</label>
              <TextField
                id="phone"
                label="Phone Number"
                required
                variant="outlined"
                className="text-filled"
                name="phone"
                type="text"
                size="small"
                fullWidth
                inputProps={{ style: { fontSize: 10 } }}
                InputLabelProps={{ style: { fontSize: 10 } }}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <span className="d-block text-danger">
                <small>{validationErrors.phoneError}</small>
              </span>
            </div>
            <div className="my-3">
              <label className="form-label">Your Email Address</label>
              <TextField
                id="email"
                label="Email"
                required
                variant="outlined"
                className="text-filled"
                type="email"
                name="email"
                size="small"
                fullWidth
                value={email}
                inputProps={{ style: { fontSize: 10 } }}
                InputLabelProps={{ style: { fontSize: 10 } }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="my-3">
              {/*
              <label className="form-label">Business Type</label>              
              <Select
                options={businessTypes}
                className="text-field"
                name="businessType"
                value={businessType}
                onChange={(e) => setBusinessType(e)}
              /> */}
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="termsCheck"
                name="termsCheck"
                checked={isTermsChecked}
                onChange={() => setIsTermsChecked(!isTermsChecked)}
              />
              <label className="form-check-label" htmlFor="check">
                I accept terms and conditions
              </label>
            </div>
            <button
              className="form-btn mx-auto my-3 p-2"
              type="submit"
              disabled={!isTermsChecked ? "true" : ""}
            >
              Get Credit Now
            </button>
          </div>
        </div>
      </form>
      <FooterRow />
    </>
  );
}
