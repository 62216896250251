import React, { useState, useEffect } from "react";
import "../App.css";
import UserListRow from "./UserListRow";

export default function UserList() { 

  const [usersArray, setUsersArray] = useState([]);
  //getting api data
  useEffect(() => {
    const fetchData = async () => {
      var request = {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      };
      const res = await fetch(
        "https://developer.phindor.com/phindor-api/public/api/wishlist/onboard",
        request
      );
      var data = await res.json();
      setUsersArray(data.data);
    };
    const intervalId = setInterval(() => {
      fetchData();
    }, 1000 * 5);
    return () => clearInterval(intervalId);
  }, [usersArray]);
  let userRows = usersArray.map((user) => (
    <UserListRow key={user.id} user={user} />
  ));
  return (  
      <div className="transparent p-2">{userRows}</div>    
  );
}
