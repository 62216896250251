import React from "react";
import "../App.css";
import {useParams} from "react-router-dom";
import FooterRow from "./FooterRow";

export default function UserWelcome() {
  const {name} = useParams();

  return (
    <>
      <span className="page-heading p-1 mx-3">lisa</span>
      <form className="register-card mx-5">
        <div className="p-1">
          <div className="m-5">
            <p className="form-heading text-uppercase">CONGRATS {name}!!!</p>
            <p className="form-subheading">
              You have received KES3000 on your lisa account to use for your
              first 60 days! Check your email for more info!
            </p>
            <div className="my-5">
              <p className="form-subheading text-center">
                We will notify you once we go live.
              </p>
              <p className="bold form-subheading text-center">
                Keep an eye on this page
              </p>
            </div>
            <div className="margin-large">
              <p className="form-subheading">Dont enjoy this alone</p>
            </div>
            <button className="form-btn mx-auto my-3 p-2">
              Copy link to Share with friends
            </button>
          </div>
        </div>
      </form>
      <FooterRow/>
    </>
  );
}
