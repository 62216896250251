import { BrowserRouter as Router, Link } from "react-router-dom";
import Contents from "./Contents.js";
import UserList from "./UserList";
import "../App.css";

export default function Page() {
  return (
    <Router>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <Contents />
          </div>
          <div className="col-md-6 bg pt-3 ">
            <UserList />
          </div>
        </div>
      </div>
    </Router>
  );
}
