import React from 'react';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import '../App.css'
import UserDirectory from './UserDirectory.js';
import UserSignup from './UserSignup.js';
import UserList from './UserList.js';
import UserWelcome from './UserWelcome';

const NotFound = () => <h2>Page Not Found</h2>;


export default function Contents() {
    return (
        <Routes>  
            <Route exact path="/" element={<UserSignup/>} />
            <Route path="/register" element={<UserSignup/>} />             
            <Route path="/welcome/:name" element={<UserWelcome/>} />            
            <Route path="/*" element={<NotFound/>} />                      
        </Routes>
    );
}