import React from "react";
import styled, { keyframes } from "styled-components";
import { bounce } from "react-animations";
import "../App.css";

export default function UserListRow(props) {
  let user = props.user;
  const bounceAnimation = keyframes`${bounce}`;

  const Bounce = styled.div`
    animation: 1s ${bounceAnimation};
  `;
  return (  
      <div className="inner-div p-1 m-3">
        <Bounce><p className="m-1">{user.name} joined just now</p></Bounce>
      </div>
    
  );
}
