import React, { useState, useEffect } from "react";
import "../App.css";
import { Link } from "react-router-dom";

export default function FooterRow(){
    return (
        <div className="container my-3 mx-auto" style={{width:"70%"}}>
            <div className="row">
                <div className="col d-flex justify-content-center">
                    <Link className="link" to={"register"}>Terms & Conditions</Link>
                </div>
                <div className="col d-flex justify-content-center">
                <Link className="link">Support</Link>
                </div>
                <div className="col d-flex justify-content-center">
                <Link className="link">Privacy Policy</Link>
                </div>
            </div>
        </div>
    );
}